<template>
  <div class="mt-5">
    <block-dashboard-wrapper title="Liste des communautés">
      <template v-slot:button>
        <button
          type="button"
          class="content-wrapper__header-button"
          @click="openCreateCommunityModal()"
        >
          Ajouter une communauté
        </button>
      </template>
      <block-communities-table
        @updateCommunity="openEditCommunityModal($event)"
        :communities="communities"
      ></block-communities-table>
    </block-dashboard-wrapper>
    <molecule-modal-wrapper :open="isModalOpen" @modalClose="closeModal()">
      <block-communities-form
        :community="modalCommunity"
        @modalClose="closeModal()"
      ></block-communities-form>
    </molecule-modal-wrapper>
  </div>
</template>

<script setup>
import MoleculeModalWrapper from "@/components/molecules/MoleculeModalWrapper.vue";
import BlockCommunitiesTable from "@/components/blocks/tables/BlockCommunitiesTable.vue";
import BlockDashboardWrapper from "@/components/blocks/BlockDashboardWrapper.vue";
import BlockCommunitiesForm from "@/components/blocks/forms/BlockCommunitiesForm.vue";
import { computed, onBeforeMount, ref } from "vue";
import {useStore} from "vuex";
const store = useStore();
// Communities 
const communities = computed( () => store.getters['communities/getAll']);
onBeforeMount( async () => {
  if(!communities.value?.length){
    try {
      await store.dispatch('communities/getAll');
    } catch (err) {
      const {message} = err.response.data;
      console.log(message);
    }
  }
});

// Modal code
const isModalOpen = ref(false);
const closeModal = () => {
  isModalOpen.value = false;
};
// Modal community code
const modalCommunity = ref({});
// Create community function
const openCreateCommunityModal = () => {
  isModalOpen.value = true;
  modalCommunity.value = {};
};
// Edit community function
const openEditCommunityModal = (community) => {
  const {createdAt, updateAt, members, owner, ...communityToUpdate} = community;
  modalCommunity.value = communityToUpdate;
  isModalOpen.value = true;
};
</script>

<style lang="scss" scoped>
</style>
