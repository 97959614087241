<template>
  <form action="#" method="POST">
    <div class="overflow-hidden">
      <div class="px-2">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Nom</label
            >
            <input
              type="text"
              name="name"
              id="name"
              autocomplete="name"
              v-model="community.name"
              class="
                mt-1
                focus:ring-primary focus:border-primary
                block
                w-full
                shadow-sm
                sm:text-sm
                border-gray-300
                rounded-md
              "
            />
          </div>
          <div class="col-span-6">
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
              >Description</label
            >
            <div class="mt-1 sm:mt-0 sm:col-span-2">
              <textarea
                id="description"
                name="description"
                rows="3"
                v-model="community.description"
                class="
                  max-w-lg
                  shadow-sm
                  block
                  w-full
                  focus:ring-primary focus:border-primary
                  sm:text-sm
                  border border-gray-300
                  rounded-md
                "
              />
              <p class="mt-2 text-sm text-gray-500">
                Écrivez quelques phrases sur la communauté.
              </p>
            </div>
          </div>
          <div class="col-span-6">
            <label for="avatar" class="block text-sm font-medium text-gray-700"
              >Avatar</label
            >
            <div class="flex items-center mt-1">
              <span class="h-10 w-10 rounded-full overflow-hidden bg-gray-100">
                <svg
                  v-show="!selectedImage"
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <img v-show="selectedImage" :src="selectedImage" alt="" />
              </span>
              <label
                for="community-avatar"
                class="
                  ml-5
                  bg-white
                  py-2
                  px-3
                  border border-gray-300
                  rounded-md
                  shadow-sm
                  text-sm
                  leading-4
                  font-medium
                  text-gray-700
                  hover:bg-gray-50
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-primary
                "
                >Modifier</label
              >
              <input
                @change="updateSelectedFile"
                type="file"
                hidden
                id="community-avatar"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="py-4 text-right px-2">
        <button
          @click.prevent="handleSubmit"
          type="submit"
          class="
            inline-flex
            justify-center
            py-2
            px-4
            border border-transparent
            shadow-sm
            text-sm
            font-medium
            rounded-md
            text-white
            bg-primary
            hover:bg-primary
            focus:outline-none
            focus:ring-2
            focus:ring-offset-2
            focus:ring-primary
          "
        >
          Soumettre
        </button>
      </div>
    </div>
  </form>
</template>

<script setup>
import { useAuth } from "@/composables/auth";
import { ref } from "vue";
import { useStore } from "vuex";
const { currentUser } = useAuth();
const { dispatch } = useStore();
const props = defineProps({
  community: {
    type: Object,
    default: {
      name: "",
      description: "",
      owner: "",
      avatar: "",
    },
  },
});
const emit = defineEmits(["modalClose"]);

// Avatar upload 
const selectedImage = ref(null);
const selectedFile = ref(null);
const updateSelectedFile = (e) => {
  selectedFile.value = e.target.files;
  selectedImage.value = URL.createObjectURL(selectedFile.value[0]);
};


// Community logic
const community = ref(props.community);
// If it's a new community
if (!community.value.name) {
  community.value.owner = currentUser.value.userId;
}
const handleSubmit = async () => {
  // Update
  if (community.value.id) {
    try {
      // Upload new avatar
      if (selectedFile.value?.length) {
        const [{ path }] = await dispatch("files/upload", selectedFile.value);
        // Add avatar to community payload
        community.value.avatar = path;
      }
      // Update community
      await dispatch("communities/update", community.value);
      emit("modalClose");
    } catch (err) {
      console.log(err);
    }
  }
  // Create
  else {
    try {
      // Upload avatar
      if (selectedFile.value?.length) {
        const [{ path }] = await dispatch("files/upload", selectedFile.value);
        // Add avatar to community payload
        community.value.avatar = path;
      }
      // Create community
      await dispatch("communities/create", community.value);
      emit("modalClose");
    } catch (err) {
      console.log(err);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>