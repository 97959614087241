<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="communities-table">
            <thead class="communities-table__header">
              <tr>
                <th scope="col" class="communities-table__header-col">Nom</th>
                <th scope="col" class="communities-table__header-col">Description</th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Modifier</span>
                </th>
              </tr>
            </thead>
            <tbody class="communities-table__body">
              <tr
                class="communities-table__row"
                v-for="community in communities"
                :key="community.id"
              >
                <td class="communities-table__row-cel">
                  <div class="flex items-center">
                    <div class="flex-shrink-0 h-10 w-10">
                      <img
                        class="h-10 w-10 rounded-full object-cover"
                        :src="imageUrl(community.avatar)"
                        alt=""
                      />
                    </div>
                    <div class="ml-4">
                      <router-link :to="{name:'ForumRoom', params:{communityName:community.name}}" target="_blank" class="text-sm font-medium text-gray-900 capitalize">
                        {{ community.name }}
                      </router-link>
                      <div class="text-sm text-gray-500">
                        {{ community.membersCount }} membres
                      </div>
                    </div>
                  </div>
                </td>
                <td class="communities-table__row-cel communities-table__row-cel--fixed">
                  <div class="text-sm text-gray-900">
                    <span>{{community.description}}</span>
                  </div>
                </td>
                <td class="communities-table__row-cel text-right text-sm font-medium">
                  <a @click.prevent="$emit('updateCommunity',community)" href="#" class="text-primary hover:text-primary/90 mr-2 cursor-pointer"
                    >Modifier</a
                  >
                  <a @click.prevent="deleteCommunity(community.id)" class="text-red-500 hover:text-red-400 cursor-pointer"
                    >Supprimer</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <block-confirmation-modal
  :open="isConfirmationModalOpen"
   @modalClose="isConfirmationModalOpen = false" 
   :content="confirmationModalContent"/> -->
</template>

<script setup>
import { computed, toRefs, ref, inject } from "vue";
import BlockConfirmationModal from "@/components/blocks/BlockConfirmationModal.vue";
import {useStore} from "vuex";
const {dispatch} = useStore();
const props = defineProps({
  communities:{
    type:Array,
    required:true
  }
});
const {communities} = toRefs(props);
const isConfirmationModalOpen = ref(false);
const confirmDeletion = ref(true);
const confirmationModalContent = ref({
  title:'Supprimer la communauté',
  description: 'Êtes-vous sûr de vouloir supprimer la communauté ?',
  primaryCta:'Supprimer'
});
const deleteCommunity = async (communityId) => {
  try {
    const deletedCommunity = await dispatch('communities/delete', communityId);
  } catch (err) {
    const {message} = err.response.data;
    console.log(message);
  }
}
const imageUrl = inject('imageUrl'); 
</script>
<style lang="scss" scoped>
.communities-table {
  @apply min-w-full divide-y divide-gray-200 table-fixed;
  &__header {
    @apply bg-gray-50;
    &-col {
      @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }
  }
  &__body {
    @apply bg-white divide-y divide-gray-200;
  }
  &__row {
    &-cel {
      @apply px-6 py-4 whitespace-nowrap;
      &--fixed{
        @apply min-w-[250px] 
        whitespace-normal;
      }
      &-status {
        @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;
        &--active {
          @apply bg-green-100 text-green-600;
        }
        &--blocked {
          @apply bg-red-100 text-red-600;
        }
        &--pending {
          @apply bg-gray-100 text-gray-600;
        }
      }
    }
  }
}
</style>
