<template>
  <div class="content-wrapper">
    <div class="content-wrapper__header">
      <div class="content-wrapper__header-container">
        <h3 class="content-wrapper__header-heading">{{ title }}</h3>
      </div>
      <div class="content-wrapper__header-container flex-shrink-0">
        <slot name="button"></slot>
      </div>
    </div>
    <div class="content-wrapper__body">
      <slot />
    </div>
  </div>
</template>
<script setup>
import {toRefs} from "vue";
const props = defineProps({
  title:{
    type:String,
    reuqired:true
  }
});
const {title} = toRefs(props);
</script>

<style lang="scss">
.content-wrapper {
  @apply bg-white px-4 py-5 border-b border-gray-200 sm:px-6;
  &__header {
    @apply -ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap;
    &-container {
      @apply ml-4 mt-2;
    }
    &-heading {
      @apply text-lg leading-6 font-medium text-gray-900;
    }
    &-button {
      @apply relative inline-flex items-center 
        px-4 py-2 
        border border-transparent 
        shadow-sm rounded-md
        text-sm font-medium text-white 
        bg-primary
        hover:bg-primary/90 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary;
    }
  }
  &__body {
    @apply pt-5;
  }
}
</style>
