<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="closeConfirmationModal">
      <div class="confirmation-modal__wrapper">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="confirmation-modal__overlay" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="confirmation-modal__center" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="confirmation-modal__container">
            <div class="confirmation-modal__header">
              <button type="button" class="confirmation-modal__button" @click="closeConfirmationModal">
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="confirmation-modal__body">
              <div class="confirmation-modal__body-header">
                <ExclamationIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
              </div>
              <div class="confirmation-modal__body-content">
                <DialogTitle as="h3" class="confirmation-modal__body-title"> {{content.title}} </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">{{content.description}}</p>
                </div>
              </div>
            </div>
            <div class="confirmation-modal__cta">
              <button type="button" class="confirmation-modal__cta-btn" @click="emit('modalSubmit',true)">{{content.primaryCta}}</button>
              <button type="button" class="confirmation-modal__cta-btn2" @click="emit('modalSubmit', false)">Cancel</button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { ref, toRefs } from 'vue'
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { ExclamationIcon, XIcon } from '@heroicons/vue/outline'
const props = defineProps({
  open:{
    type:Boolean,
    default:true
  },
  content:{
    type:Object,
    required:true
  }
});
const emit = defineEmits(['modalClose','modalSubmit']);
const {open, content} = toRefs(props);
const closeConfirmationModal = () => {
  emit('modalClose');
};
</script>

<style lang="scss" scoped>
  .confirmation-modal{
    &__dialog{
      @apply fixed z-10 inset-0 overflow-y-auto;
    }
    &__wrapper{
      @apply flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0;
    }
    &__overlay{
      @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
    }
    &__center{
      @apply hidden sm:inline-block sm:align-middle sm:h-screen;
    }
    &__container{
      @apply relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6;
    }
    &__header{
      @apply hidden sm:block absolute top-0 right-0 pt-4 pr-4;
    }
    &__button{
      @apply bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none;
    }
    &__body{
      @apply sm:flex sm:items-start;
      &-header{
        @apply mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10;
      }
      &-content{
        @apply mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left;
        
      }
      &-title{
        @apply text-lg leading-6 font-medium text-gray-900;
      }
    }
    &__cta{
      @apply mt-5 sm:mt-4 sm:flex sm:flex-row-reverse;
      &-btn{
        @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm;
      }
      &-btn2{
        @apply mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary sm:mt-0 sm:w-auto sm:text-sm;
      }
    }
  }
</style>