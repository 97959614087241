<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="open" v-if="open">
    <Dialog
      as="div"
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="handleModalClose"  
    >
      <div
        class="modal__container"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="modal__dialog-overlay"
          />
        </TransitionChild>
        <span
          class="modal__hidden"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="modal__body"
          >
            <slot />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/outline";

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});
const emit = defineEmits(["modalClose"]);
const handleModalClose = () => {
  emit('modalClose')
};
</script>

<style lang="scss" scoped>
  .modal{
    &__dialog{
      @apply fixed z-50 inset-0 overflow-y-auto
      top-0 left-1/2 w-full h-full -translate-x-1/2;
      &-overlay{
        @apply fixed inset-0 
        bg-gray-500 bg-opacity-75 
        transition-opacity;
      }
    }
    &__container{
      @apply flex items-end justify-center 
      min-h-screen 
      pt-4 px-4 pb-20 
      text-center 
      sm:block sm:p-0
    }
    &__body{
      @apply inline-block align-bottom 
      w-full max-w-[1000px]
      bg-white rounded-lg 
      px-4 pt-5 pb-4 
      text-left overflow-hidden 
      shadow-xl 
      transform transition-all 
      sm:my-8 sm:align-middle sm:p-6
    }
    &__hidden{
      @apply hidden sm:inline-block sm:align-middle sm:h-screen;
    }
  }
</style>